import React, { useState, useEffect } from "react";
// Components
import PageHeader from "../../components/molecules/pageHeader";
import { Input, Form, Select } from "antd";
import styles from "./country.module.scss";
import { Table } from "../../components/organisms";
import { createColumns } from "./helpers/columns";
import countryService from "../../services/countryService";
import { Modal } from "antd";
import { toast } from "react-toastify";
import _map from "lodash/map";

import { Button, INPUT_TYPES } from "../../components/atoms";
function Country() {
  const [form] = Form.useForm();

  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [editCountry, setEditCountry] = useState(null);
  const defaultValues = {
    countryName: editCountry ? editCountry?.countryName : "",
    currency: editCountry ? editCountry?.currency : "",
    countryLanguage: editCountry ? editCountry?.countryLanguage : "",
    countryVatPercent: editCountry ? `${editCountry?.countryVatPercent}` : "",
    countryGoodPercent: editCountry ? `${editCountry?.countryGoodPercent}` : "",
    position: editCountry ? `${editCountry?.position}` : "",
  };
  const handleOpenEditFreelancerForm = (freelancerDetails) => {
    setEditCountry(freelancerDetails);
    setShowModal(true);
  };
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);
  const handleOnInActive = () => {};

  const columns = createColumns({
    onEdit: handleOpenEditFreelancerForm,
    onInActive: handleOnInActive,
  });

  const fetchCountries = () => {
    setLoading(true);
    countryService
      .fetchCountries()
      .then((response) => {
        setCountryList(response);
      })
      .catch((err) => console.log(err, "Error"))
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchCountries();
  }, []);

  function onFinish(values) {
    setLoading(true);

    if (editCountry) {
      countryService
        .updateCountry(editCountry._id, {
          ...values,
          _id: editCountry._id,
        })
        .then((response) => {
          if (response.success) {
            toast.success("Update Successful!!");
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setShowModal(false);
          fetchCountries();
          setLoading(false);
        });
    } else {
      setLoading(true);
      countryService
        .createCountry({ ...values })
        .then((response) => {
          toast.success("Country Added Successful!!");

          fetchCountries();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setShowModal(false);
          setLoading(false);
        });
    }
  }

  const renderPriceOptions = (option) => {
    return (
      <Select.Option key={`${Math.random()}`} value={`${option.position}`}>
        {option.position}
      </Select.Option>
    );
  };
  const renderOptionsCurrency = (option) => {
    return (
      <Select.Option key={`${Math.random()}`} value={`${option.code}`}>
        {option.code + " - " + option.country}
      </Select.Option>
    );
  };
  const listOfCurrency = [
    { code: "USD", country: "United States" },
    { code: "AED", country: "United Arab Emirates" },
    { code: "AFN", country: "Afghanistan" },
    { code: "ALL", country: "Albania" },
    { code: "AMD", country: "Armenia" },
    { code: "ANG", country: "Netherlands Antilles" },
    { code: "AOA", country: "Angola" },
    { code: "ARS", country: "Argentina" },
    { code: "AUD", country: "Australia" },
    { code: "AWG", country: "Aruba" },
    { code: "AZN", country: "Azerbaijan" },
    { code: "BAM", country: "Bosnia and Herzegovina" },
    { code: "BBD", country: "Barbados" },
    { code: "BDT", country: "Bangladesh" },
    { code: "BGN", country: "Bulgaria" },
    { code: "BIF", country: "Burundi" },
    { code: "BMD", country: "Bermuda" },
    { code: "BND", country: "Brunei" },
    { code: "BOB", country: "Bolivia" },
    { code: "BRL", country: "Brazil" },
    { code: "BSD", country: "Bahamas" },
    { code: "BWP", country: "Botswana" },
    { code: "BYN", country: "Belarus" },
    { code: "BZD", country: "Belize" },
    { code: "CAD", country: "Canada" },
    { code: "CDF", country: "Congo" },
    { code: "CHF", country: "Switzerland" },
    { code: "CLP", country: "Chile" },
    { code: "CNY", country: "China" },
    { code: "COP", country: "Colombia" },
    { code: "CRC", country: "Costa Rica" },
    { code: "CVE", country: "Cape Verde" },
    { code: "CZK", country: "Czech Republic" },
    { code: "DJF", country: "Djibouti" },
    { code: "DKK", country: "Denmark" },
    { code: "DOP", country: "Dominican Republic" },
    { code: "DZD", country: "Algeria" },
    { code: "EGP", country: "Egypt" },
    { code: "ETB", country: "Ethiopia" },
    { code: "EUR", country: "European Union" },
    { code: "FJD", country: "Fiji" },
    { code: "FKP", country: "Falkland Islands" },
    { code: "GBP", country: "United Kingdom" },
    { code: "GEL", country: "Georgia" },
    { code: "GIP", country: "Gibraltar" },
    { code: "GMD", country: "Gambia" },
    { code: "GNF", country: "Guinea" },
    { code: "GTQ", country: "Guatemala" },
    { code: "GYD", country: "Guyana" },
    { code: "HKD", country: "Hong Kong" },
    { code: "HNL", country: "Honduras" },
    { code: "HTG", country: "Haiti" },
    { code: "HUF", country: "Hungary" },
    { code: "IDR", country: "Indonesia" },
    { code: "ILS", country: "Israel" },
    { code: "INR", country: "India" },
    { code: "ISK", country: "Iceland" },
    { code: "JMD", country: "Jamaica" },
    { code: "JPY", country: "Japan" },
    { code: "KES", country: "Kenya" },
    { code: "KGS", country: "Kyrgyzstan" },
    { code: "KHR", country: "Cambodia" },
    { code: "KMF", country: "Comoros" },
    { code: "KRW", country: "South Korea" },
    { code: "KYD", country: "Cayman Islands" },
    { code: "KZT", country: "Kazakhstan" },
    { code: "LAK", country: "Laos" },
    { code: "LBP", country: "Lebanon" },
    { code: "LKR", country: "Sri Lanka" },
    { code: "LRD", country: "Liberia" },
    { code: "LSL", country: "Lesotho" },
    { code: "MAD", country: "Morocco" },
    { code: "MDL", country: "Moldova" },
    { code: "MGA", country: "Madagascar" },
    { code: "MKD", country: "North Macedonia" },
    { code: "MMK", country: "Myanmar" },
    { code: "MNT", country: "Mongolia" },
    { code: "MOP", country: "Macau" },
    { code: "MUR", country: "Mauritius" },
    { code: "MVR", country: "Maldives" },
    { code: "MWK", country: "Malawi" },
    { code: "MXN", country: "Mexico" },
    { code: "MYR", country: "Malaysia" },
    { code: "MZN", country: "Mozambique" },
    { code: "NAD", country: "Namibia" },
    { code: "NGN", country: "Nigeria" },
    { code: "NIO", country: "Nicaragua" },
    { code: "NOK", country: "Norway" },
    { code: "NPR", country: "Nepal" },
    { code: "NZD", country: "New Zealand" },
    { code: "PAB", country: "Panama" },
    { code: "PEN", country: "Peru" },
    { code: "PGK", country: "Papua New Guinea" },
    { code: "PHP", country: "Philippines" },
    { code: "PKR", country: "Pakistan" },
    { code: "PLN", country: "Poland" },
    { code: "PYG", country: "Paraguay" },
    { code: "QAR", country: "Qatar" },
    { code: "RON", country: "Romania" },
    { code: "RSD", country: "Serbia" },
    { code: "RUB", country: "Russia" },
    { code: "RWF", country: "Rwanda" },
    { code: "SAR", country: "Saudi Arabia" },
    { code: "SBD", country: "Solomon Islands" },
    { code: "SCR", country: "Seychelles" },
    { code: "SEK", country: "Sweden" },
    { code: "SGD", country: "Singapore" },
    { code: "SHP", country: "Saint Helena" },
    { code: "SLE", country: "Sierra Leone" },
    { code: "SOS", country: "Somalia" },
    { code: "SRD", country: "Suriname" },
    { code: "STD", country: "São Tomé and Príncipe" },
    { code: "SZL", country: "Eswatini" },
    { code: "THB", country: "Thailand" },
    { code: "TJS", country: "Tajikistan" },
    { code: "TOP", country: "Tonga" },
    { code: "TRY", country: "Turkey" },
    { code: "TTD", country: "Trinidad and Tobago" },
    { code: "TWD", country: "Taiwan" },
    { code: "TZS", country: "Tanzania" },
    { code: "UAH", country: "Ukraine" },
    { code: "UGX", country: "Uganda" },
    { code: "UYU", country: "Uruguay" },
    { code: "UZS", country: "Uzbekistan" },
    { code: "VND", country: "Vietnam" },
    { code: "VUV", country: "Vanuatu" },
    { code: "WST", country: "Samoa" },
    { code: "XAF", country: "Central African States" },
    { code: "XCD", country: "Eastern Caribbean States" },
    { code: "XOF", country: "West African States" },
    { code: "XPF", country: "French Pacific Territories" },
    { code: "YER", country: "Yemen" },
    { code: "ZAR", country: "South Africa" },
    { code: "ZMW", country: "Zambia" },
  ];

  return (
    <div className="container">
      {showModal && (
        <Modal
          open={showModal}
          footer={null}
          onCancel={() => {
            setShowModal(false);
            setEditCountry(null);
          }}
          okText="Create Country"
        >
          <div>
            <h3>{editCountry ? "Edit Country" : "Create Country"}</h3>
            <div style={{ marginTop: 10 }}>
              <Form
                initialValues={defaultValues}
                layout="vertical"
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  label="Country Name"
                  name="countryName"
                  rules={[
                    { required: true, message: "Please input Country name!" },
                  ]}
                >
                  <Input
                    type={INPUT_TYPES.TEXT}
                    label={"Enter Country Name"}
                    placeholder={"Enter Country Name"}
                  />
                </Form.Item>
                <Form.Item
                  label="Country Language"
                  name="countryLanguage"
                  rules={[
                    {
                      required: true,
                      message: "Please input Country Language!",
                    },
                  ]}
                >
                  <Input
                    type={INPUT_TYPES.TEXT}
                    label={"Enter Language"}
                    placeholder={"Enter Country Language"}
                  />
                </Form.Item>

                <Form.Item
                  name="position"
                  label="Amount Position"
                  rules={[
                    { required: true, message: "Please select Position!" },
                  ]}
                >
                  <Select
                    mode="single"
                    placeholder="Amount Position"
                    className={styles.select}
                  >
                    {_map(
                      [{ position: "Left" }, { position: "Right" }],
                      renderPriceOptions
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Country Currency"
                  name="currency"
                  rules={[
                    {
                      required: true,
                      message: "Please input Country Currency Name!",
                    },
                  ]}
                >
                  <Select
                    mode="single"
                    showSearch
                    placeholder="Currency Name"
                    className={styles.select}
                    filterOption={(input, option) => {
                      return (option?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {_map([...listOfCurrency], renderOptionsCurrency)}
                  </Select>
                  {/* <Input
                    type={INPUT_TYPES.TEXT}
                    label={"Enter Currency Short Form"}
                    placeholder={"Enter Currency Short Form"}
                  /> */}
                </Form.Item>

                <Form.Item
                  label="Country Vat Price"
                  name="countryVatPercent"
                  min={1}
                  rules={[
                    {
                      required: true,
                      message: "Please input Country Vat  Price!",
                    },
                  ]}
                >
                  <Input
                    type={INPUT_TYPES.NUMBER}
                    label={"Enter Price of Vat"}
                    min={1}
                    placeholder={"Enter Price of Vat"}
                  />
                </Form.Item>
                <Form.Item
                  label="Country  Good Vat Price"
                  name="countryGoodPercent"
                  rules={[
                    {
                      required: true,
                      message: "Please input Country Good Vat  Price!",
                    },
                  ]}
                >
                  <Input
                    type={INPUT_TYPES.NUMBER}
                    label={"Enter Price of Good Vat"}
                    placeholder={"Enter Price Good of Vat"}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    className={styles.saveButton}
                    label={editCountry ? "Edit Country" : "Create Country"}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
      )}

      <PageHeader
        title="Country"
        actions={[
          {
            title: "+ Add Country",
            handler: () => setShowModal(true),
          },
        ]}
      />
      <div className="card">
        <Table
          data={countryList}
          columns={columns}
          showSearch={false}
          searchProps={{
            placeholder: "Search Sports",
          }}
          onReload={() => fetchCountries()}
          onSearch={() => {}}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default Country;
