// Lodash
import _property from "lodash/property";

const id = _property("_id");
const isActive = _property("is_active");
const userName = _property("username");
const firstName = _property("firstName");
const lastName = _property("lastName");
const createdAt = _property("createdAt");
const email = _property("email");
const emailVerified = _property("email_verified");
const fullName = _property("fullname");
const gender = _property("gender");
const profileImageUrl = _property("profile_pic");
const contactNumber = _property("contact_no");
const dateOfBirth = _property("dob");
const role = _property("role");
const postsCount = _property("postsCount");
const packagePercentage = _property("bussiness.package_percentage");
const activityPercentage = _property("bussiness.activity_percentage");
const slotPercentage = _property("bussiness.slot_percentage");
const businessUserId = _property("bussiness._id");
const freelancerUserId = _property("_id");
const appVersionAndroid = _property("appVersion.version_android");
const appVersionIOS = _property("appVersion.version_ios");
const lastOpen = _property("lastOpen");
const sports_played = _property("sports_played");
const favorite_sports = _property("favorite_sports");
const sportsListName = _property("sportsListName");
const location = _property("location");
const latitude = _property("latitude");
const longitude = _property("longitude");
const venues = _property("venues");
const countryId = _property("country_id");

export default {
  id,
  isActive,
  userName,
  firstName,
  lastName,
  createdAt,
  email,
  emailVerified,
  fullName,
  gender,
  profileImageUrl,
  contactNumber,
  dateOfBirth,
  role,
  postsCount,
  packagePercentage,
  activityPercentage,
  slotPercentage,
  businessUserId,
  freelancerUserId,
  lastOpen,
  sports_played,
  favorite_sports,
  sportsListName,
  appVersionAndroid,
  appVersionIOS,
  location,
  latitude,
  longitude,
  venues,
  countryId,
};
