import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import { Table } from "../../../../components/organisms";

// Helpers
import { createColumns } from "./helpers";

// Services
import { salesService } from "../../../../services";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../constants";
import { DEFAULT_PAGINATION_DETAILS } from "./constants";

// Redux Actions
import { setSales, setLoading } from "../../../../redux/slices/salesSlice";

// Styles
import styles from "./partnerSales.module.scss";

function SalesSummary() {
  const dispatch = useDispatch();

  const [totalSales, setTotalSales] = useState(0);
  const [searchText, setSearchText] = useState();
  const [paginationDetails, setPaginationDetails] = useState(
    DEFAULT_PAGINATION_DETAILS
  );

  const sales = useSelector((state) => state?.sales?.items);
  const loading = useSelector((state) => state?.sales?.loading);
  const countryList = useSelector((state) => state?.login?.countryList);

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    dispatch(setLoading(true));
    salesService
      .fetchSales({ pageNumber, pageSize, searchText })
      .then((salesDetails = EMPTY_OBJECT) => {
        const sales = salesDetails?.docs || EMPTY_ARRAY;
        setTotalSales(salesDetails?.totalDocs);
        dispatch(setSales(sales));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, [paginationDetails]);

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleReloadSales = () => {
    setPaginationDetails({
      ...paginationDetails,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({...DEFAULT_PAGINATION_DETAILS});
  }

  const columns = createColumns(countryList);

  return (
    <div className={styles.sales}>
      <Table
        columns={columns}
        data={sales}
        loading={loading}
        showSearch
        showReload
        searchText={searchText}
        paginationOptions={{
          current: paginationDetails.pageNumber,
          total: totalSales,
        }}
        onReload={handleReloadSales}
        onPaginationChange={handlePaginationChange}
        onSearch={handleSearch}
      />
    </div>
  );
}

export default SalesSummary;
