import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../constants";

const INITIAL_STATE = {
  user: EMPTY_OBJECT,
  countryList : EMPTY_ARRAY

};

const loginSlice = createSlice({
  name: "login",
  initialState: INITIAL_STATE,
  reducers: {
    addUser: (state, action = {}) => {
      const { payload: user } = action;
      state.user = user;
    },
    addCountryList: (state, action = {}) => {
      const { payload: countryList } = action;
      state.countryList = countryList;
    },

  },
});

const { addUser, addCountryList } = loginSlice.actions;
const loginReducer = loginSlice.reducer;

export { addUser, loginReducer, addCountryList };
export default loginSlice;
