const { REACT_APP_URL, NODE_ENV } = process.env
console.log({ REACT_APP_URL, NODE_ENV })
let URL = "https://api.dudiapp.com/v2";
let Business_URL = "https://business.dudiapp.com";

if (NODE_ENV === "development") {
    // URL = "http://localhost:3000/v2";
    // Business_URL = "http://localhost:3002";
    URL = "https://api-dev.dudiapp.com/v2";
    Business_URL = "https://business-dev.dudiapp.com";
}

if (REACT_APP_URL === "dev") {
    URL = "https://api-dev.dudiapp.com/v2";
    Business_URL = "https://business-dev.dudiapp.com";
}

const BASE_URL = URL

export { BASE_URL, Business_URL };

