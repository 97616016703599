import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";
import _map from "lodash/map";

// Components
import Form from "../../../../components/atoms/form";
import Button from "../../../../components/atoms/button";
import Drawer from "../../../../components/atoms/drawer";
import {
  Input,
  Password,
} from "../../../../components/atoms/input";
import { PickerWithType } from "../../../../components/atoms/datePicker/DatePicker";
import { toast } from "../../../../components/atoms/toaster";
import GooglePlacesAutoComplete from "../../../../components/molecules/googlePlacesAutoComplete";
import { getID } from "../../../../utils/globle";
import { Select } from "antd";

// Reader
import accountReader from "../../../../readers/businessAccount";

// Styles
import styles from "./createAccountForm.module.scss";

// Services
import accountService from "../../../../services/businessAccountsService";
import freelancerService from "../../../../services/freelancerService";
import venuesService from "../../../../services/venuesService";
import countryService from "../../../../services/countryService";

// const Option = { Select };

function CreateAccountForm({
  visible,
  onClose,
  accountDetails,
  isEditMode,
  onReload,
  sportsList,
}) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const location = accountReader.location(accountDetails);
  const latitude = accountReader.latitude(accountDetails) || 25.1044748;
  const longitude = accountReader.longitude(accountDetails) || 55.2372069;
  // const sportsListName = accountReader.sportsListName(accountDetails);
  const favorite_sports = accountReader.favorite_sports(accountDetails);
  let prevSports = favorite_sports;
  const bussiness_id = accountReader.businessUserId(accountDetails);
  const accountID = accountReader.id(accountDetails);
  const firstName = accountReader.firstName(accountDetails);
  const lastName = accountReader.lastName(accountDetails);
  const username = accountReader.userName(accountDetails);
  const email = accountReader.email(accountDetails);
  const contactNumber = accountReader.contactNumber(accountDetails);
  const dateOfBirth = accountReader.dateOfBirth(accountDetails);
  const status = accountReader.isActive(accountDetails);
  const packagePercentage = accountReader.packagePercentage(accountDetails);
  const activityPercentage = accountReader.activityPercentage(accountDetails);
  const slotPercentage = accountReader.slotPercentage(accountDetails);
  const countryId = accountReader.countryId(accountDetails);

  const max_pitches = accountDetails?.venues?.max_pitches;
  const players_occupy = accountDetails?.venues?.players_occupy;
  const average_cost = accountDetails?.venues?.average_cost;
  const opening_time = accountDetails?.venues?.opening_time;
  const closing_time = accountDetails?.venues?.closing_time;

  let title = "Create New Account";
  const renderSportsOptions = (option) => {
    return (
      <Select.Option
        key={`${option._id}@${option.name}`}
        value={`${option._id}@${option.name}`}
      >
        {option.name}
      </Select.Option>
    );
  };

  const getSportsName = (id) => {
    return sportsList.find((sport) => sport._id === id);
  };

  // getting list of fav sports name
  if (prevSports && prevSports.length > 0 && isEditMode) {
    prevSports = prevSports.map((sports) => ({
      ...sports,
      name: getSportsName(sports._id)?.name,
    }));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = {
    firstName: firstName || null,
    lastName: lastName || null,
    username: username || null,
    email: email || null,
    password: null,
    contact_no: contactNumber || null,
    dob: dateOfBirth ? moment(dateOfBirth) : "",
    is_active: status || true,
    package_percentage: packagePercentage || 0,
    activity_percentage: activityPercentage || 0,
    slot_percentage: slotPercentage || 0,
    favorite_sports: [],
    location: location || [],
    max_pitches: max_pitches || 1,
    players_occupy: players_occupy || 10,
    average_cost: average_cost || 0,
    opening_time: null,
    closing_time: null,
    country_id: countryId ?? "675c2ef9ef520a4b1cfe909e",
  };

  // default values for freelancer venue
  const defaultVenue = {
    name: username,
    business_id: bussiness_id || null, // add user id after creating freelancer
    is_active: status || true,
    latitude: latitude || 0,
    longitude: longitude || 0,
    location: {
      address: location || null,
      coordinates: [latitude || 0, longitude || 0],
    },
    max_pitches: max_pitches || 1,
    players_occupy: players_occupy || 10,
    average_cost: average_cost || 0,
    opening_time: null,
    closing_time: null,
    sports_played: prevSports || [],
    is_freelancer_venue: false,
    country_id: countryId ?? "675c2ef9ef520a4b1cfe909e",

  };

  if (isEditMode) {
    title = "Edit Account";
    defaultValues.favorite_sports = _map(
      prevSports,
      (sport) => `${sport._id}@${sport.name}`
    );
    defaultValues.opening_time = opening_time ? moment(opening_time) : null;
    defaultValues.closing_time = closing_time ? moment(closing_time) : null;
    defaultVenue.opening_time = opening_time ? moment(opening_time) : null;
    defaultVenue.closing_time = closing_time ? moment(closing_time) : null;
  }

  const createVenue = (venueDetails) => {
    ///// now create default venue
    venuesService
      .createVenue(venueDetails)
      .then((response) => {
        if (response.success) {
          toast.success("New Venue Added Successfully!!");
          form.resetFields();
        } else {
          toast.warning("Something went wrong while updating!");
        }
      })
      .catch((error) => {
        console.log(error.response, "error.createVenue");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateVenue = (venue_id, venueDetails) => {
    // console.log(venueDetails, 'venueDetails')
    venuesService
      .updateVenue(venue_id, venueDetails)
      .then((response) => {
        if (response.success) {
          toast.success("Update Venue Successfully!!");
          form.resetFields();
        } else {
          toast.warning("Something went wrong while updating!");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    countryService.fetchCountries().then((response) => {
      setCountryList(response);
    });
  }, []);

  const getVenueByUserId = (bus_id, venueDetails) => {
    // now create default venue
    venuesService
      .fetchFreelanceVenueDetails(bus_id)
      .then((response) => {
        venueDetails.business_id = bussiness_id;
        if (response.docs && !response.docs.length) {
          createVenue(venueDetails);
        } else {
          updateVenue(response.docs[0]?._id, venueDetails);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderCountryOptions = (option) => {
    return (
      <Select.Option key={`${option._id}`} value={`${option._id}`}>
        {option.countryName}
      </Select.Option>
    );
  };
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = (values) => {
    // console.log(values,"values")
    values.favorite_sports = getID(values.favorite_sports);
    const finalValues = { ...values };
    const address = values.location?.address;
    const lat = values.location?.coordinates?.lat;
    const lng = values.location?.coordinates?.lng;
    if (address && lat && lng) {
      finalValues.location = address;
      finalValues.latitude = lat;
      finalValues.longitude = lng;
      // ////////////////////for Venue
      defaultVenue.latitude = lat;
      defaultVenue.longitude = lng;
      defaultVenue.address = address;
      defaultVenue.location = {
        address: address,
        coordinates: [lat, lng],
      };
    } else {
      delete finalValues.location;
      delete finalValues.latitude;
      delete finalValues.longitude;
      delete defaultVenue.latitude;
      delete defaultVenue.longitude;
      delete defaultVenue.address;
      delete defaultVenue.location;
    }

    finalValues.favorite_sports = finalValues.favorite_sports
      ? sportsList.filter((sports) =>
          finalValues.favorite_sports.includes(sports._id)
        )
      : [];

    // ////////////////////for Venue
    defaultVenue.name = `${finalValues.firstName} ${
      finalValues?.lastName ? finalValues.lastName : ""
    }`;
    defaultVenue.sports_played = values.favorite_sports;
    defaultVenue.max_pitches = finalValues.max_pitches;
    defaultVenue.players_occupy = finalValues.players_occupy;
    defaultVenue.average_cost = finalValues.average_cost;
    defaultVenue.opening_time = finalValues.opening_time;
    defaultVenue.closing_time = finalValues.closing_time;

    setLoading(true);
    if (isEditMode) {
      onClose();
      accountService
        .updateBusinessAccount(accountID, finalValues)
        .then((response) => {
          if (response.success) {
            getVenueByUserId(bussiness_id, defaultVenue);
            onReload();
            toast.success("Update Successful!!");
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      accountService
        .createBusinessAccount(finalValues)
        .then((response) => {
          if (response.success) {
            defaultVenue.business_id = response?.data?._id;
            createVenue(defaultVenue);
            toast.success("Business Account Added Successfully!!");
            form.resetFields();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const checkUserName = (username) => {
    let regx = new RegExp(/^[a-z0-9_.]+$/);
    if (regx.test(username))
      freelancerService
        .checkUserName({ username })
        .then(({ success, data }) => {
          if (success) {
            if (data.exist) toast.warning("Username is already in use!");
            else toast.success("Username is available");
          } else toast.warning("Something went wrong to check username!");
        })
        .catch((error) => {
          console.log(error);
        });
  };

  return (
    <Drawer title={title} open={visible} onClose={onClose}>
      <div className={styles.container}>
        <Form
          initialValues={defaultValues}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input account first name!",
              },
            ]}
          >
            <Input placeholder="Enter account first name" />
          </Form.Item>
          <Form.Item label="Last Name" name="lastName">
            <Input
              placeholder="Enter account last name"
              subText="Venue name on map will be combination of name and lastname "
            />
          </Form.Item>
          <Form.Item
            label="User Name"
            name="username"
            onBlur={(value) => checkUserName(value.target.value)}
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[a-z0-9_.]+$/),
                message: `Usernames can only have:
                - Lowercase Letters (a-z)
                - Numbers (0-9)
                - Dots (.)
                - Underscores (_)!`,
              },
            ]}
          >
            <Input placeholder="Enter account userName" />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input placeholder="Enter e-mail" />
          </Form.Item>
          {!isEditMode && (
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Password placeholder="Enter password" className={styles.input} />
            </Form.Item>
          )}
          <Form.Item
            label="Contact No"
            name="contact_no"
            rules={[
              {
                required: true,
                message: "Please input account contact number!",
              },
            ]}
          >
            <Input placeholder="Enter account contact number" />
          </Form.Item>
          <Form.Item
            name="country_id"
            label="Country"
            rules={[{ required: true, message: "Please select Country!" }]}
          >
            <Select
              mode="single"
              placeholder="Select Country"
              className={styles.select}
            >
              {_map(countryList, renderCountryOptions)}
            </Select>
          </Form.Item>
          <Form.Item label="Address" name="location">
            <GooglePlacesAutoComplete
              lat={isEditMode ? latitude : 0}
              lng={isEditMode ? longitude : 0}
              place={isEditMode ? location : ""}
            ></GooglePlacesAutoComplete>
          </Form.Item>

          <Form.Item
            name="favorite_sports"
            label="Sports Played"
            rules={[
              { required: true, message: "Please select sports played!" },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select sports played"
              className={styles.select}
            >
              {_map(sportsList, renderSportsOptions)}
            </Select>
          </Form.Item>
          <Form.Item name="package_percentage" label="Package Percentage">
            <Input
              placeholder="Enter Package Percentage"
              type="number"
              min={0}
              max={99}
            />
          </Form.Item>
          <Form.Item name="activity_percentage" label="Activity Percentage">
            <Input
              placeholder="Enter Activity Percentage"
              type="number"
              min={0}
              max={99}
            />
          </Form.Item>
          <Form.Item
            name="slot_percentage"
            label="Calendar Activity Percentage"
          >
            <Input
              placeholder="Enter Calendar Activity Percentage"
              type="number"
              min={0}
              max={99}
            />
          </Form.Item>
          <Form.Item
            name="max_pitches"
            label="Max Number of Pitches"
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input placeholder="Enter Max Pitches" type="number" min={0} />
          </Form.Item>
          <Form.Item name="average_cost" label="Average Cost">
            <Input placeholder="Enter average cost" type="number" />
          </Form.Item>
          <Form.Item name="players_occupy" label="Players Occupancy">
            <Input placeholder="Enter Player Occupancy" />
          </Form.Item>
          <Form.Item name="opening_time" label="Opening Time">
            <PickerWithType type={"time"} use12Hours format="h:mm a" />
          </Form.Item>
          <Form.Item name="closing_time" label="Closing Time">
            <PickerWithType type={"time"} use12Hours format="h:mm a" />
          </Form.Item>
          {/* <Form.Item
            name="is_active"
            label="Status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="select status" className={styles.select}>
              <Option value={true}>Active</Option>
              <Option value={false}>InActive</Option>
            </Select>
          </Form.Item> */}
          <Form.Item name="dob" label="Date of Birth">
            <PickerWithType type={"date"} format={"YYYY/MM/DD"} />
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.saveButton}
              label={"Save"}
              type="primary"
              htmlType="submit"
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}

CreateAccountForm.propTypes = {
  visible: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onClose: PropTypes.func,
  sportsList: PropTypes.array,
};

CreateAccountForm.defaultProps = {
  visible: false,
  isEditMode: false,
  onClose: _noop,
};

export default CreateAccountForm;
